<template>
  <section>
    <template v-if="maxDate && minDate">
      <table-header
        @update="onUpdateFilter"
        @reset="onResetFilter"
        v-bind="{
          minDate,
          maxDate,
          currentPage: page,
        }"
      >
      </table-header>
    </template>
    <table-body
      v-bind="{
        currentPage: page,
        pageCount,
        itemCount,
        pageSize: page_size
      }"
      @update:page-size="onPerPageChange"
      @update:page="onPageChange"
    >
      <b-table
        class="position-relative"
        v-bind="{
          items: clients,
          fields,
          striped,
          hover,
          responsive,
          emptyText,
          showEmpty: clients && !clients.length && !busy,
          perPage: page_size,
          busy: loading,
        }"
        @sort-changed="onSort"
      >
        <template #cell(phone)="{ item }">
          <a :href="`tel:+${item.phone}`">{{  item.phone | VMask('+#(###) ###-##-##') }}</a>
        </template>
        <template #cell(last_order_creation_date)="{ item }">
          {{ item.last_order_creation_date
            ? $dayjs(item.last_order_creation_date * 1000).format('DD.MM.YYYY')
            : ''
          }}
        </template>
        <template #cell(actions)="{ item }">
          <table-buttons
            v-bind="{
              disabled: hasPermission,
            }"
            @edit="onEdit({ ...item })"
            @delete="onDelete({ ...item })"
          ></table-buttons>
        </template>

        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1"></b-spinner>
            <span>Загрузка...</span>
          </div>
        </template>
      </b-table>
    </table-body>

    <common-dialog
      v-bind="{
        title: modal.delete.visible ? modal.delete.title : '',
        value: modal.delete.visible
      }"
      @confirm="onDeleteItem"
    >
      <p> {{ `${modal.delete.content} ${full_name}?`}} </p>
    </common-dialog>

  </section>
</template>

<script>
import { AuthNameSpace, RoleCollection } from '@/store/modules/auth/types'
import { mapState, mapActions } from 'vuex'
import { ClientNameSpace, ClientActionTypes } from '@/store/modules/client/types'

import { BTable } from 'bootstrap-vue'

import TableHeader from '@/components/common/table/TableHeader'
import TableBody from '@/components/common/table/TableBody'
import TableButtons from '@/components/common/buttons/TableButtons'

import { columns, modal } from '@/config/clients'
import { duration } from '@/config'

import { throttle } from 'lodash'

export default {
  name: 'Clients',
  components: {
    BTable,
    TableHeader,
    TableBody,
    TableButtons,
    CommonDialog: () => import('@/components/common/common-dialog.vue'),
  },
  data() {
    return {
      id: '',
      full_name: '',
      date_from: '',
      date_to: '',
      search: '',
      page_size: 10,
      page: 1,
      modal,
      city_id: this.active_city,
      sort_by: {
        sortBy: 'full_name',
        sortDesc: 'asc',
      },
      // TODO update on back req for get time
      busy: true,
      time: true,
      loading: true,
    }
  },
  props: {
    striped: {
      type: Boolean,
      default() {
        return true
      },
    },
    hover: {
      type: Boolean,
      default() {
        return true
      },
    },
    responsive: {
      type: Boolean,
      default() {
        return true
      },
    },
    emptyText: {
      type: String,
      default() {
        return 'Нет данных'
      },
    },
  },

  computed: {
    ...mapState(ClientNameSpace, {
      clients: 'clients',
      pageCount: 'pageCount',
      itemCount: 'itemCount',
      minDate: 'date_from',
      maxDate: 'date_to',
    }),
    ...mapState(AuthNameSpace, {
      userData: 'userData',
      active_city: 'city_id',
    }),
    fields() {
      return columns
    },
    hasPermission() {
      return this.userData.role === RoleCollection.florist || this.userData.role === RoleCollection.courier
    },
  },

  async beforeMount() {
    // TODO to delete after back update
    await this.loadCollection()
    this.time = false
    await this.loadCollection()
    this.$nextTick(() => {
      this.busy = false
    })
  },

  methods: {
    ...mapActions(ClientNameSpace, {
      [ClientActionTypes.LoadClientCollection]: ClientActionTypes.LoadClientCollection,
      [ClientActionTypes.CreateClient]: ClientActionTypes.CreateClient,
      [ClientActionTypes.DeleteClient]: ClientActionTypes.DeleteClient,
    }),

    loadCollection: throttle(async function() {
      this.loading = true
      await this[ClientActionTypes.LoadClientCollection]({ ...this.$data })
      if (this.clients) this.loading = false
    }, duration),

    delete: throttle(async function() {
      await this[ClientActionTypes.DeleteClient]({ ...this.$data })
    }, duration),

    async onEdit({ id }) {
      this.$router.push({ name: 'client-edit', params: { id } })
    },

    onDelete({ id, full_name }) {
      this.id = id
      this.full_name = full_name

      this.modal.delete.visible = true
    },

    async onDeleteItem({ confirmed }) {
      try {
        if (confirmed) {
          await this.delete()
          if (this.clients.length === 1 && this.page !== 1) this.page -= 1
          await this.loadCollection()
        }
      } catch {
        this.modal.delete.visible = false
      }

      this.modal.delete.visible = false
    },

    async onUpdateFilter(context) {
      Object.assign(this.$data, context)
      this.page = 1
      await this.loadCollection()
    },

    async onResetFilter(context) {
      Object.assign(this.$data, { date_from: '', date_to: '' })

      await this.loadCollection()
    },

    async onPerPageChange(count) {
      this.page = 1
      this.page_size = parseInt(count, 10)

      await this.loadCollection()
    },

    async onPageChange(count) {
      this.page = parseInt(count, 10)

      await this.loadCollection()
    },

    async onSort({ sortBy, sortDesc }) {
      if (sortBy) {
        if (sortBy !== this.sort_by.sortBy) {
          this.sort_by.sortBy = sortBy === 'order_number' ? 'order_count' : 'client_price' ? 'client_price' : sortBy
        }
        this.sort_by.sortDesc = sortDesc ? 'desc' : 'asc'
      }

      await this.loadCollection()
    },
  },
  watch: {
    async active_city(city_id) {
      this.city_id = city_id
      if (this.page > 1) this.page = 1
      Object.assign(this.$data, { date_from: '', date_to: '' })
      await this.loadCollection()
    },
  },
}
</script>

<style scoped>
</style>
