const columns = Object.freeze([
  {
    key: 'full_name',
    label: 'ФИО Клиента',
    thClass: 'w-20 text-center align-middle',
    tdClass: 'text-center align-middle',
    sortable: true,
  },
  {
    key: 'phone',
    label: 'Номер телефона',
    thClass: 'w-15 text-center align-middle',
    tdClass: 'text-center align-middle',
  },
  {
    key: 'last_order_creation_date',
    label: 'Последний заказ',
    thClass: 'w-15 text-center align-middle',
    tdClass: 'text-center align-middle',
  },
  {
    key: 'order_number',
    label: 'Кол-во заказов',
    thClass: 'w-15 text-center align-middle',
    tdClass: 'text-center align-middle',
    sortable: true,
  },
  {
    key: 'price',
    label: 'Выручка',
    thClass: 'w-15 text-center align-middle',
    tdClass: 'text-center align-middle',
    sortable: true,
  },
  {
    key: 'actions',
    name: 'actions',
    label: '',
  },
])

const actions = Object.freeze([
  {
    variant: 'primary',
    icon: 'EditIcon',
    to: { name: 'clients-item', params: ['id'] },
  },
])

const modal = {
  delete: {
    title: 'Удалить клиента?',
    content: 'Вы дейсвительно хотите удалить клиента',
    visible: false,
  },
}

export { columns, actions, modal }
